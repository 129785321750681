<template>
  <div class="">
    <div class="w-full flex text-c14 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/5">
        {{ item.reference }}
      </div>

      <div class="w-1/4 flex items-center ml-4 text-c16">
        12-10-2021, 15h37
      </div>

      <div class="w-1/5 ml-4">
        Montures
      </div>


      <div class="w-1/6 ml-4">
        12
      </div>

      <div class="w-1/6 ml-4">
        ----
      </div>

      <div class="w-1/4 ml-4">
        <bouton
          label="En attente"
          height="32px"
          color="#FBA705"
          background="#FFF6E6"
          radius="10px"
          size="14px"
          class="w-1/2"
        />
        <bouton
          label="Reçue"
          height="32px"
          color="#00C24E"
          background="#E5F9ED"
          radius="10px"
          size="14px"
          v-if="1===2"
          class="w-1/2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'
import email from '../../../assets/icons/email.svg'
import down from '../../../assets/icons/down.svg'
import bouton from '../../helper/add/button'

export default {
  name: "Index",
  components: {
    bouton
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        down
      },
    }
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
