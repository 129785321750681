<template>
  <div>
    <popupCharge
      :activation="charge"
      v-if="charge"
    />
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            @click="$emit('oga', false)"
            original
          />
          <div class="ml-4 w-3/5">
            {{ donnes.name }}
          </div>
        </div>

        <div class="w-1/2 flex justify-end">
          <bouton
            label="Créer une facture"
            class="w-2/5"
            @oga="activeSelect =! activeSelect"
          />
        </div>
      </div>

      <div class="flex justify-end w-full" v-if="activeSelect">
        <div class="w-1/5 absolute">
          <selectList :option="optionB" @info="backSelect"/>
        </div>
      </div>

      <div class="w-full mt-10">
        <div class="flex justify-end w-full">
          <div class="w-1/2">
            <inputo
              placeholder="Rechercher..."
              height="54px"
              :icon="icons.search"
              class="w-2/3"
            />
          </div>
          
          <div class="w-1/2 flex">
            <div class="w-1/3 ml-4">
              <datepicker
                :icon="icons.calendar"
                placeholder="Date"
                height="54px"
              />
            </div>

            <div class="w-1/3 ml-4">
              <multiselect
                :icon="icons.filter"
                height="54px"
                background="white"
                :icon-width="25"
                value-t="Réception"
                :option="filtre"
              />
            </div>

            <div class="w-1/3 ml-4">
              <multiselect
                :icon="icons.filter"
                height="54px"
                background="white"
                :icon-width="25"
                value-t="Articles"
                :option="filtre"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="charge && supplierData === null"
        class="flex justify-center mt-10"
      >
        <easy-spinner
          type="dots"
          size="70"
        />
      </div>

      <globalData class="mt-10" />
    </div>
  </div>
</template>

<script>
import globalData from './globalData.vue'
import back from '../../../assets/icons/backBut.svg'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import popupCharge from '../../popup/popupCharge'
import bouton from '../../helper/add/button.vue'
import search from '../../../assets/icons/search.svg'
import calendar from '../../../assets/icons/calendar.svg'
import filter from '../../../assets/icons/filter.svg'
import inputo from '../../helper/form/input'
import multiselect from '../../helper/form/multiselect'
import datepicker from '../../helper/form/datePicker.vue'
import selectList from '../../helper/form/selectListe.vue'

export default {
  name: "Index",

  components: {
    popupCharge,
    globalData,
    bouton,
    multiselect,
    inputo,
    datepicker,
    selectList
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        back,
        search,
        calendar,
        filter
      },
      supplierData: [],
      charge: false,
      invoice: [],
      selectedItem: null,
      periode: ['Mois en cours', '3 derniers mois', '6 derniers mois'],
      filtre: ['CSNL', 'CNLNS', 'ENCOURS', 'CLNS'],
      optionB: ['Achat', 'Verres commandés'],
      activeSelect: false
    }
  },

  created () {
    console.log('skdkfs')
    console.log(this.donnes)
  },

  methods: {
    selected () {
      this.activeDetail = true
      this.activeSelect = false
    },

    backSelect (b) {
      if( b === this.optionB[0]){
        this.$router.push('/nouvellefacture')
      } else {
        this.$router.push('/nouvellefacture')
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
