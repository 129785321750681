<template>
  <div class="p-10 pr-16 all text-left">
    <div v-if="!selectedItem">
      <div>
        <div
          class="flex items-center"
        >
          <div class="w-full flex text-left text-c36 items-center font-c6">
            <div class="w-3/5">
              Factures internes
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="charge"
        class="flex justify-center mt-10"
      >
        <easy-spinner
          type="dots"
          size="70"
        />
      </div>

      <div class="mt-10 flex">
        <div
          class="w-1/2 mr-6 flex p-6 items-center rounded-5 bg-white cursor-pointer"
          @click="selected('métiers')"
        >
          <div class="w-full ">
            <div class="text-c36 font-c7">
              03
            </div>
            <div class="text-c18">
              Fournisseurs métiers
            </div>
          </div>
        </div>

        <div
          class="w-1/2 flex p-6 items-center rounded-5 bg-white cursor-pointer"
          @click="selected('généraux')"
        >
          <div class="w-full ">
            <div class="text-c36 font-c7">
              74
            </div>
            <div class="text-c18">
              Fournisseurs généraux
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedItem">
      <fournisseur
        :title="selectedItem"
        @oga="backFournisseur"
      />
    </div>
  </div>
</template>

<script>
import fournisseur from '../../component/cdevs/facture/fournisseur.vue'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'

export default {
  name: "Index",

  components: {
    fournisseur
  },

  data () {
    return {
      supplierData: [],
      charge: false,
      selectedItem: null
    }
  },

  created () {
  
  },

  methods: {
    selected (item) {
      this.selectedItem = item
    },

    backFournisseur(a) {
      this.selectedItem = a
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
